<template>
  <div class="fluid" id="overtimeSettingList">
    <div>
      <div class="overtime-setting-list-dev">
        <v-card elevation="0" class="px-3 py-2" style="width: 100%">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h4 class="indigo--text" style="font-weight: bold; margin: auto">
                OVERTIME SETTING
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDataFromApi"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="overtime-setting-list-toolbar-1"
                  flat
                  style="
                    height: 60px;
                    border-radius: 10px 10px 0 0;
                    padding-top: 15px;
                  "
                >
                  <div class="d-flex align-content-start">
                    <div
                      style="margin-right: 15px; height: 45px"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        style="font-size: 12px"
                        @click="addOvertimeSetting"
                        elevation="2"
                        tilerounded="5px"
                        color="primary"
                        class="white--text"
                        small
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="">
                              mdi-plus
                            </v-icon>
                          </template>
                          <span>Add Setting</span>
                        </v-tooltip>
                      </v-btn>
                    </div>
                    <div style="width: 210px">
                      <v-autocomplete
                        v-model="paramAPI.company_id"
                        label="Company"
                        item-text="name"
                        item-value="id"
                        return-object
                        dense
                        outlined
                        clearable
                        @change="companyWatcher"
                        :items="dropdown.company"
                        :disabled="
                          getUserProfile.employee.company.plant_id != 6
                        "
                      ></v-autocomplete>
                    </div>
                  </div>
                </v-toolbar>
              </div>
            </v-col>

            <v-col cols="12" style="width: 100%">
              <v-data-table
                v-if="result.length > 0 || !skeleton_loading"
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                :page="paramAPI.page"
                @update:page="updatePage"
                :items-per-page="paramAPI.itemsPerPage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div
                    style="
                      font-size: 14px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                  </div>
                </template>
                <template v-slot:[`item.enable_overtime_planning`]="{ item }">
                  <div
                    :style="`color:${
                      item.enable_overtime_planning ? 'blue' : 'red'
                    }; font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: center; align-items:center;`"
                  >
                    {{ item.enable_overtime_planning }}
                  </div>
                </template>
                <template
                  v-slot:[`item.allow_overtime_request_wo_planning`]="{ item }"
                >
                  <div
                    :style="`color:${
                      item.allow_overtime_request_wo_planning ? 'blue' : 'red'
                    }; font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: center; align-items:center;`"
                  >
                    {{ item.allow_overtime_request_wo_planning }}
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.createdAt) }}
                  </div>
                </template>
                <template v-slot:[`item.updatedAt`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.updatedAt) }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="rowClick(item)"
                        small
                        color="primary"
                        class="mr-2"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        color="error"
                        @click.stop="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-skeleton-loader
                v-else
                style="width: 100%"
                type="table-heading, table-tbody, table-tfoot"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="settingDialog" persistent max-width="400px">
      <v-card v-if="form !== null">
        <v-card-title>
          <span class="text-h5">Add/Edit Group</span>
        </v-card-title>
        <v-form ref="entryForm2" @submit.prevent="saveSetting">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  style="
                    padding: 0 0 0 15px;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                  "
                >
                  <v-col
                    cols="12"
                    style="
                      padding: 0;
                      justify-content: flex-start;
                      align-items: center;
                      display: flex;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Enable Overtime Planning
                    </p>
                    <v-checkbox
                      v-model="form.enable_overtime_planning"
                      :false-value="false"
                      :true-value="true"
                      style="margin: 0 0 0 10px; height: 25px; padding: 0"
                    ></v-checkbox>
                  </v-col>
                </v-col>
                <v-col
                  cols="12"
                  style="
                    padding: 0 0 0 15px;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                  "
                >
                  <v-col
                    cols="12"
                    style="
                      padding: 0;
                      justify-content: flex-start;
                      align-items: center;
                      display: flex;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Allow overtime request w/o planning
                    </p>
                    <v-checkbox
                      v-model="form.allow_overtime_request_wo_planning"
                      :false-value="false"
                      :true-value="true"
                      style="margin: 0 0 0 10px; height: 25px; padding: 0"
                    ></v-checkbox>
                  </v-col>
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Company
                  </p>
                  <v-autocomplete
                    v-model="form.company"
                    :items="dropdown.company"
                    :rules="rules.companyRules"
                    style="height: 40px"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Overtime multiplier off type
                  </p>
                  <v-autocomplete
                    v-model="form.overtime_multiplier_off_type"
                    :items="[1, 2, 3]"
                    style="height: 40px"
                    dense
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeSettingDialog">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations /*  mapActions */ } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  name: 'msovertime',
  data: () => ({
    editDialog: false,
    settingDialog: false,
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'id',
      sortType: 'ASC',
      company_id: null,
      department_id: null
    },
    totalData: 0,
    form: null,
    detail: null,
    isEdit: false,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Enable overtime plannig',
        value: 'enable_overtime_planning',
        align: 'center',
        sortable: false,
        width: '120px'
      },
      {
        text: 'Allow overtime request w/o planning',
        value: 'allow_overtime_request_wo_planning',
        align: 'center',
        sortable: false,
        width: '150px'
      },
      {
        text: 'Multiplier off type',
        value: 'overtime_multiplier_off_type',
        align: 'center',
        sortable: false,
        width: '120px'
      },
      {
        text: 'Created at',
        value: 'createdAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Updated at',
        value: 'updatedAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    rules: {
      companyRules: []
    },
    dropdown: {
      company: []
    },
    skeleton_loading: true
  }),

  async mounted() {
    this.paramAPI.company_id = {
      id: Number(this.getUserProfile.employee.company.plant_id),
      name: this.getUserProfile.employee.company.name
    }
    this.paramAPI.department_id = this.getUserProfile.employee.department_id

    if (this.getOvertimeSettingPageQuery !== null) {
      this.paramAPI = this.getOvertimeSettingPageQuery
    }
    setTimeout(async () => {
      this.initDropdown()
      await this.getDataFromApi()
    }, 200)
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile', 'getOvertimeSettingPageQuery'])
  },
  watch: {},
  methods: {
    // ...mapActions(['dropdownPlant']),
    ...mapMutations(['setOvertimeSettingPageQuery']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async initDropdown() {
      this.dropdownCompany()
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    companyWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },

    async dropdownCompany() {
      console.log('company >>>')
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          console.log('company >>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.company = res.data.data)
          }
          return (this.dropdown.company = [])
        })
        .catch((err) => {
          console.log('company err>>>', err)
          return (this.dropdown.company = [])
        })
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable() {
      // master/universal/overtimesetting/list?keyword=&limit=10&offset=0&order_by=id&order_type=ASC&company_id=6
      let url = `${this.hrsApi}master/universal/overtimesetting/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.sortBy}&order_type=${this.paramAPI.sortType}`
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id.id}`
      }
      console.log('url >>>>', url)
      this.setOvertimeSettingPageQuery(this.paramAPI)
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    enableEdit() {
      var oldForm = this.form
      this.isEdit = !this.isEdit
      if (!this.isEdit) {
        this.form = oldForm
        if (this.form.act === 'add') {
          this.closeDialog()
        }
      }
    },

    rowClick(pItem) {
      console.log(pItem)
      this.form = {
        act: 'update',
        id: pItem.id,
        enable_overtime_planning: pItem.enable_overtime_planning,
        allow_overtime_request_wo_planning:
          pItem.allow_overtime_request_wo_planning,
        overtime_multiplier_off_type: pItem.overtime_multiplier_off_type,
        company: {
          id: pItem.company_id,
          name: pItem.company_name
        }
      }
      setTimeout(() => {
        this.settingDialog = !this.settingDialog
      }, 300)
    },
    closeDialog() {
      this.form = null
      this.detail = null
      this.isEdit = false
      setTimeout(() => {
        this.editDialog = false
      }, 300)
    },
    addOvertimeSetting() {
      this.form = {
        act: 'add',
        company: null,
        overtime_multiplier_off_type: null,
        enable_overtime_planning: false,
        allow_overtime_request_wo_planning: false
      }
      setTimeout(() => {
        this.settingDialog = !this.settingDialog
      }, 300)
    },
    saveSetting() {
      this.rules.companyRules = [(v) => !!v || 'Company is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm2.validate()) {
          // self.createNewForm()
          const form = {
            act: self.form.act,
            name: '-',
            company_id: self.form.company.id,
            company_name: self.form.company.name,

            enable_overtime_planning: self.form.enable_overtime_planning,
            allow_overtime_request_wo_planning:
              self.form.allow_overtime_request_wo_planning,
            overtime_multiplier_off_type: self.form.overtime_multiplier_off_type
          }
          if (form.act === 'update') {
            Object.assign(form, { id: self.form.id })
          }
          self.submitSetting(form)
        }
      })
    },
    async submitSetting(form) {
      await axios
        .post(`${this.hrsApi}master/universal/overtimesetting/save`, form)
        .then(async (res) => {
          console.log(res.data)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
            this.closeSettingDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    async deleteItem(pItem) {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.hrsApi}master/universal/overtimesetting/delete/${pItem.id}`
            )
            .then(async (res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    closeSettingDialog() {
      this.form = null
      setTimeout(() => {
        this.settingDialog = false
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss">
#overtimeSettingList {
  position: relative;
  .overtime-setting-list-dev {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .overtime-setting-list-toolbar-1 {
      .overtime-setting-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #overtimeSettingList {
    .overtime-setting-list-dev {
      .overtime-setting-list-toolbar-1 {
        .overtime-setting-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
